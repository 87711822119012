import React, { useState, useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../images/SitesLogo/logo.svg";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../contexts/ThemeContext";
import "./styles.scss";

const HeaderNav = ({ path }) => {
  const [preUrl, setPreUrl] = useState("");
  const [host, setHost] = useState("");

  const context = useContext(ThemeContext);

  useEffect(() => {
    const winLoc = window.location.toString();

    const preUrl = /dev/.test(winLoc) ? "-dev" : "";
    setPreUrl(preUrl);

    const urlFirstDotIndex = winLoc.indexOf(".");
    let host = winLoc.slice(urlFirstDotIndex);
    setHost(host);
  }, []);

  const { t, i18n } = useTranslation();

  const toggleLanguage = (lang) => {
    window.location.href = `//${lang}${preUrl}${host}`;
  };
  
  return (
    <Row
      className={`header-nav ${path === "/404" ? "for-not-found" : ""}`}
      lg={12}
      xl={12}
      md={12}
      sm={12}
      xs={12}
    >
      <Col
        className="header-nav-logo-mobile header-logo"
        lg={3}
        xl={5}
        md={3}
        sm={3}
        xs={6}
      >
        <div
          className="header-nav-logo" ref={context.pageTop}
          onClick={() => {
            window.location.href = window.location.origin;

          }}
        >
          <img className="logo" src={logo} alt="Utilitium logo" />
        </div>
      </Col>
      <Col
        className="header-nav-contacts-mobile"
        lg={9}
        xl={6}
        md={9}
        sm={9}
        xs={6}
      >
        <div className="header-nav-wrapper">
          <div className="header-top-contacts">
            <div className="contacts-sale">
              <span className="contacts-title">{t("Contacts.1")}</span>
              <a href="tel:+380952794212" className="contacts-phone">
                095-279-42-12
              </a>
            </div>
            <div className="contacts-support">
              <span className="contacts-title">{t("Contacts.2")}</span>
              <a href="tel:+380504005993" className="contacts-phone">
                050-400-59-93
              </a>
            </div>
          </div>
          <div className="language-btn-group">
            <div
              className={`language-btn ${
                i18n.language === "ua" ? "active" : ""
              }`}
              onClick={() => toggleLanguage("ua")}
            >
              <span>ua</span>
            </div>

            <div
              className={`language-btn ${
                i18n.language === "en" ? "active" : ""
              }`}
              onClick={() => toggleLanguage("en")}
            >
              <span>en</span>
            </div>
            
            <div
              className={`language-btn ${
                i18n.language === "ru" ? "active" : ""
              }`}
              onClick={() => toggleLanguage("ru")}
            >
              <span>ru</span>
            </div>

          </div>
        </div>
      </Col>
    </Row>
  );
};

export default HeaderNav;
