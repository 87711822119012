import React, { useContext, useState, useEffect } from "react";
import ThemeContext from "../../contexts/ThemeContext";
import Container from "react-bootstrap/Container";
import HeaderNav from "../HeaderNav";
import HeaderNavScroling from "../HeaderNavScroling";
import "./styles.scss";

const Header = () => {
  const currentTheme = useContext(ThemeContext);
  const [distanse, setDistanse] = useState("");

  const topDistanse = (scrollToTop) => {
    setDistanse(scrollToTop);
  };

  const [path, setPath] = useState('');

  useEffect(() => {
    const currentPath = window.location.pathname;
    setPath(currentPath)
  })

  const notFoundPage = path === "/404";

  return (
    <>
      <Container>
        <HeaderNav path={path}/>
      </Container>
      {!notFoundPage && <HeaderNavScroling
        topDistanse={topDistanse}
        distanse={distanse}
        path={path}
      />}
    </>
  );
};

export default Header;
