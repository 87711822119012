import React, { useEffect, useContext } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import logo from "../../images/SitesLogo/logo.svg";
import { useTranslation } from "react-i18next";
import ThemeContext from "../../contexts/ThemeContext";
import "./styles.scss";
import { useState } from "react";

const HeaderNavScroling = ({ topDistanse, distanse }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language);
  const [preUrl, setPreUrl] = useState("");
  const [host, setHost] = useState("");

  useEffect(() => {
    setLang(i18n.language);
    const url = window.location.toString();

    const preUrl = /dev/.test(url) ? "-dev" : "";
    setPreUrl(preUrl);

    const urlFirstDotIndex = url.indexOf(".");
    let host = url.slice(urlFirstDotIndex);
    setHost(host);
  }, [i18n.language]);

  const toggleLanguage = (lang) => {
    window.location.href = `//${lang}${preUrl}${host}`;
  };

  useEffect(() => {
    document.addEventListener("scroll", () => {
      topDistanse(window.pageYOffset);
    });

    return document.removeEventListener("scroll", () => { });
  }, [topDistanse]);

  const context = useContext(ThemeContext);
  const scrollToForm = context.scrollToForm;
  const scrollToTop = context.scrollToTop;

  return (
    <>
      <div
        className={`scroling-header-wrapper ${distanse > 0 ? "active" : ""}`}
      >
        <Container>
          <Row className="header-nav" lg={12} xl={12} md={12} sm={12} xs={12}>
            <Col
              className="header-nav-logo-mobile"
              lg={3}
              xl={5}
              md={3}
              sm={3}
              xs={6}
            >
              <div
                className="header-nav-logo"
                onClick={() => scrollToTop(context)}
              >
                <img className="logo" src={logo} alt="Utilitium logo" />
              </div>
            </Col>
            <Col
              className="header-nav-contacts-mobile"
              lg={9}
              xl={host.includes('info') ? 6 : 7}
              md={9}
              sm={9}
              xs={6}
            >
              <div className="header-nav-wrapper">

                <div className="header-top-contacts">
                  <div className="contacts-sale">
                    <span className="contacts-title">{t("Contacts.1")}</span>
                    <a href="tel:+380952794212" className="contacts-phone">
                      095-279-42-12
                    </a>
                  </div>
                  <div className="contacts-support">
                    <span className="contacts-title">{t("Contacts.2")}</span>
                    <a href="tel:+380504005993" className="contacts-phone">
                      050-400-59-93
                    </a>
                  </div>
                </div>
                <div className={host.includes('info') ? "language-btn-group info" : "language-btn-group"}>
                  <div
                    className={`language-btn ${i18n.language === "ua" ? "active" : ""
                      }`}
                    onClick={() => toggleLanguage("ua")}
                  >
                    <span>ua</span>
                  </div>

                  <div
                    className={`language-btn ${i18n.language === "en" ? "active" : ""
                      }`}
                    onClick={() => toggleLanguage("en")}
                  >
                    <span>en</span>
                  </div>

                  <div
                    className={`language-btn ${i18n.language === "ru" ? "active" : ""
                      }`}
                    onClick={() => toggleLanguage("ru")}
                  >
                    <span>ru</span>
                  </div>
                </div>

                <div
                  className={host.includes('info') ? "hidden" : "consultation-button"}
                  onClick={() => scrollToForm(context)}
                >
                  {t("HeaderNavScroling.1")}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeaderNavScroling;
